import "./App.css";
import { IoMdColorWand } from 'react-icons/io';

function App() {

  let ChangeTheme = () => {
    if (document.body.style.backgroundColor == "black") {
      document.body.style.color = "black"
      document.body.style.backgroundColor = "white"
    } else {
      document.body.style.color = "white"
      document.body.style.backgroundColor = "black"
    }
  }

  let countLines = () => {
    let text = document.getElementById("raw").value;
    let lines = text.split("\n");
    let count = document.getElementById("totalCount");
    if (text.length === 0) {
      count.innerHTML = 0;
    } else {
      count.innerHTML = lines.length;
    }
  };

  const IPv4regex =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  const IPv6regex =
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/gi;

  let formatContent = () => {
    let raw = document.getElementById("raw").value;
    let formatted = "";
    let content = raw.split("\n");
    content.forEach((element) => {
      if (element.includes("/")) {
        let ele = element.split("/");
        if (ele[1] === "") {
          formatted += `${ele[0]},site,final`;
        } else {
          formatted += `${element},regex,final`;
        }
      } else if (element.includes("*")) {
        if (element.charAt(0) === "*") {
          formatted += `${element.slice(1,element.length)},site,final`;
        } else {
          formatted += `${element},regex,final`;
        }
      } else if (element.includes(":")) {
        formatted += `${element},regex,final`;
      } else if (IPv4regex.test(element) || IPv6regex.test(element)) {
        formatted += `${element},site,final`;
      } else {
        formatted += `${element},site,final`;
      }
    });
    document.getElementById("formatted").value = formatted
      .toString()
      .replace(/,final/g, "\n");
  };

  return (
    <div>
      <header>
        <h1>Cisco Feed Formatter</h1>
        <div id="theme" onClick={ChangeTheme}><IoMdColorWand /></div>
      </header>
      <main>
        <div id="textarea">
          <textarea id="raw" onChange={countLines}></textarea>
          <textarea id="formatted" disabled></textarea>
        </div>
        <div id="tools">
          <button onClick={formatContent}>format</button>
          <p>
            Total: <span id="totalCount">0</span>
          </p>
        </div>
      </main>
      <footer>
        &#169; 2023 The Nabeel Hassan
      </footer>
    </div>
  );
}

export default App;
